import { handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://feabc06b0d124f929c9b57e468704dbd@o361685.ingest.sentry.io/4504205246922752',
  tracesSampleRate: 1.0,
  tracePropagationTargets: [],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
